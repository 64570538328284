<template>
  <div id="b2c" class="main-body">
    <div id="nav-white" />
    <div id="services-front" class="smarttruck-bg">
      <h1 class="zoomIn">Smart Truck</h1>
    </div>

    <div id="content">
      <h1 class="fadeIn">
        Introducing Smart Truck: Your Smart Choice for Reliable Delivery
        Solutions
      </h1>
      <p class="fadeIn">
        Welcome to Smart Truck, where we connect cold truck drivers with clients
        who need their goods delivered safely and efficiently. Whether you're a
        truck driver with a refrigerated vehicle or a business in need of
        reliable delivery services, Smart Truck is here to help. Our innovative
        system allows any cold truck to join our network, ensuring that we
        always have the capacity to meet your delivery needs. From perishable
        goods to temperature-sensitive products, our drivers are equipped to
        handle it all with care. But wait, there's more! In addition to our cold
        truck delivery services, Smart Truck also offers freight forwarding and
        chartered truck services. Whatever your logistics requirements may be,
        we've got you covered. Choose Smart Truck for hassle-free deliveries and
        peace of mind. Join our growing network today!
      </p>
    </div>

    <b-col id="visit-system" align-h="center" class="fadeIn">
      <div class="d-flex justify-content-center">
        <img src="@/assets/images/icons/main/st-logo.png" />
      </div>

      <div class="my-2 text-center">
        <h1>Become a Smart Truck driver now!</h1>
        <h6 class="mt-2">
          Click below to register as a driver or to contact us.
        </h6>
      </div>

      <div class="d-flex justify-content-center mt-2">
        <a class="login-button mr-1" href=""> Register </a>
        <router-link class="sl-button sl-white" :to="{ name: 'Contact' }">
          Contact us
        </router-link>
      </div>
    </b-col>
  </div>
</template>
<script>
import intersectionObserverMixin from "@/mixins/intersectionObserverMixin";
import VisitSystem from "@/components/VisitSystem.vue";

export default {
  components: { VisitSystem },
  mixins: [intersectionObserverMixin],
  mounted() {
    this.setupIntersectionObserver(".fadeIn", 0.5);
    this.setupIntersectionObserver(".zoomIn", 0.1);
  },
};
</script>
